import React, {useEffect} from 'react'
import './carousel.css'
import Slider from 'react-slick'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import aos from "aos"
import "aos/dist/aos.css"

const openLink = (url) => {
  window.open(url, '_blank');
};

// const data =[
//   {
//     name: 'Tejash Seth',
//     img: 'https://innogeeks-images.s3.ap-south-1.amazonaws.com/Tejash+Seth.jpg',
//     review: 'Core Lead',
//     link: ['https://linktr.ee/tejash11']
//   },
//   {
//     name: 'Anvash Singh',
//     img: 'https://innogeeks-images.s3.ap-south-1.amazonaws.com/Anvansh+Singh.png',
//     review: 'Core Lead',
//     link: ['https://www.linkedin.com/in/anvansh/']
//   },
//   {
//     name: 'Anshul Nigam',
//     img: 'https://innogeeks-images.s3.ap-south-1.amazonaws.com/Anshul+Nigam.jpg',
//     review: 'Core Lead',
//     link: ['https://linktr.ee/anshul_13j']
//   },
//   {
//     name: 'Anjuman Hasan',
//     img: 'https://innogeeks-images.s3.ap-south-1.amazonaws.com/AnjumanHasan.jpeg',
//     review: 'Core Lead',
//     link: ['https://www.linkedin.com/in/anjuman-hasan/']
//   },
//   {
//     name: 'Anshul Gurjar',
//     img: 'https://innogeeks-images.s3.ap-south-1.amazonaws.com/Anshul+Gurjar.JPG',
//     review: 'Core Lead',
//     link: ['https://www.linkedin.com/in/anshul-gurjar-88b872257/']
//   },
//   {
//     name: 'Ayush Dubey',
//     img: 'https://innogeeks-images.s3.ap-south-1.amazonaws.com/AyushDubey.jpeg',
//     review: 'Core Lead',
//     link: ['http://ayushdubey.carrd.co']
//   },
//   {
//     name: 'Anupam Tiwari',
//     img: 'https://innogeeks-images.s3.ap-south-1.amazonaws.com/Anupam.jpeg',
//     review: 'Core Lead',
//     link:['https://www.linkedin.com/in/anupam1603/']
//   },
//   {
//     name: 'Ambuj Kulshrestha',
//     img: 'https://innogeeks-images.s3.ap-south-1.amazonaws.com/Ambuj+Kulshreshtha.jpg',
//     review: 'Core Lead',
//     link:['https://linktr.ee/ambuj1211']
//   },
//   {
//     name: 'Arjit Goyal',
//     img: 'https://innogeeks-images.s3.ap-south-1.amazonaws.com/Arjit+Goyal.jpg',
//     review: 'Core Lead',
//     link:['https://linktr.ee/iamarjitgoyal']
//   },
//   {
//     name: 'Jyoti Ojha',
//     img: 'https://innogeeks-images.s3.ap-south-1.amazonaws.com/Jyoti+Ojha.jpg',
//     review: 'Core Lead',
//     link:['https://linktr.ee/jyoti0802']
//   },
//   {
//     name: 'Abhinav Saxena',
//     img: 'https://innogeeks-images.s3.ap-south-1.amazonaws.com/IMG_7524.JPG',
//     review: 'Core Lead',
//     link:['https://www.linkedin.com/in/abhinav-saxena-dev/']
//   },
//   {
//     name: 'Kritish Shukla',
//     img: 'https://innogeeks-images.s3.ap-south-1.amazonaws.com/Kritish.jpg',
//     review: 'Core Lead',
//     link:['https://www.linkedin.com/in/kritish-shukla-805902210/']
//   },
//   {
//     name: ' Soumen Paul',
//     img: 'https://innogeeks-images.s3.ap-south-1.amazonaws.com/SoumenPaul.jpg',
//     review: 'Core Lead',
//     link:['https://linktr.ee/soumenpaul7']
//   },
//   {
//     name: 'Vishesh Kumar Singh',
//     img: 'https://innogeeks-images.s3.ap-south-1.amazonaws.com/VisheshKumarSingh.jpg',
//     review: 'Core Lead',
//     link: ['https://linktr.ee/visheshks04']
//   },
//   {
//     name: 'Vidushi Pandey',
//     img: 'https://innogeeks-images.s3.ap-south-1.amazonaws.com/Vidushi+Pandey.jpg',
//     review: 'Core Lead',
//     link: ['https://tr.ee/BOpXu3cXly']
//   }
// ]
const data = [
  {
    name: 'Aditya Pachauri',
    img: 'https://innogeeks-images.s3.ap-south-1.amazonaws.com/Aditya+Pachauri.jpg',
    review: 'Core Lead',
    link: ['https://linktr.ee/adityapachauri']
  },
  {
    name: 'Alisha Raghav',
    img: 'https://innogeeks-images.s3.ap-south-1.amazonaws.com/Alisha.jpg',
    review: 'Core Lead',
    link: ['https://www.linkedin.com/in/alisharaghav/']
  },
  {
    name: 'Aryan Mishra',
    img: 'https://innogeeks-images.s3.ap-south-1.amazonaws.com/Aryan.png',
    review: 'Core Lead',
    link: ['https://www.linkedin.com/in/aryanmishra29/']
  },
  {
    name: 'Ayush Prasad',
    img: 'https://innogeeks-images.s3.ap-south-1.amazonaws.com/ayushPrasad.png',
    review: 'Core Lead',
    link: ['https://www.linkedin.com/in/ayush-prasad-47534b226/']
  },
  {
    name: 'Bhavishya Chaudhary',
    img: 'https://innogeeks-images.s3.ap-south-1.amazonaws.com/Bhavishya.png',
    review: 'Core Lead',
    link: ['https://www.linkedin.com/in/bhavishya02/']
  },
  {
    name: 'Harsh Singh',
    img: 'https://innogeeks-images.s3.ap-south-1.amazonaws.com/Harsh.jpg',
    review: 'Core Lead',
    link: ['https://www.linkedin.com/in/the-harsh-singh/']
  },
  {
    name: 'Kapil Singh',
    img: 'https://innogeeks-images.s3.ap-south-1.amazonaws.com/Kapil+Singh.jpg',
    review: 'Core Lead',
    link: ['https://linktr.ee/kapil2003']
  },
  {
    name: 'Mrigya Sahai',
    img: 'https://innogeeks-images.s3.ap-south-1.amazonaws.com/Mrigya.jpg',
    review: 'Core Lead',
    link: ['https://www.linkedin.com/in/mrigya-sahai-246842196/']
  },
  {
    name: 'Nayan Pathak',
    img: 'https://innogeeks-images.s3.ap-south-1.amazonaws.com/Nayan+Pathak.jpg',
    review: 'Core Lead',
    link: ['https://www.linkedin.com/in/nayan-pathak-softwaredeveloper/']
  },
  {
    name: 'Neha Maurya',
    img: 'https://innogeeks-images.s3.ap-south-1.amazonaws.com/Neha.jpg',
    review: 'Core Lead',
    link: ['https://www.linkedin.com/in/neha-maurya-6a8771226/']
  },
  {
    name: 'Nivedita Rai',
    img: 'https://innogeeks-images.s3.ap-south-1.amazonaws.com/core+lead.png',
    review: 'Core Lead',
    link: ['https://www.linkedin.com/in/nivedita5759/~']
  },
  {
    name: 'Pranjal Raj',
    img: 'https://innogeeks-images.s3.ap-south-1.amazonaws.com/pranjal.jpg',
    review: 'Core Lead',
    link: ['https://www.linkedin.com/in/pranjal-raj-79596a23a/']
  },
  {
    name: 'Rachitavya Sharma',
    img: 'https://innogeeks-images.s3.ap-south-1.amazonaws.com/rachitavya.jpg',
    review: 'Core Lead',
    link: ['https://www.linkedin.com/in/rachitavya/']
  },
  {
    name: 'Sambhrant Tiwari',
    img: 'https://innogeeks-images.s3.ap-south-1.amazonaws.com/Sambhrant.jpg',
    review: 'Core Lead',
    link: ['https://www.linkedin.com/in/sambhrant-tiwari-3998b5218/']
  },
  {
    name: 'Shivam Raj',
    img: 'https://innogeeks-images.s3.ap-south-1.amazonaws.com/Shivam+Raj.png',
    review: 'Core Lead',
    link: ['https://www.linkedin.com/in/shivam-raj-45502b225/']
  },
  {
    name: 'Shubhi Pandey',
    img: 'https://innogeeks-images.s3.ap-south-1.amazonaws.com/Shubhi.jpg',
    review: 'Core Lead',
    link: ['https://www.linkedin.com/in/shubhi-pandey/']
  },
  {
    name: 'Suraj Upadhyay',
    img: 'https://innogeeks-images.s3.ap-south-1.amazonaws.com/Suraj.png',
    review: 'Core Lead',
    link: ['https://www.linkedin.com/in/suraj-upadhayay-533b22264/']
  },
  {
    name: 'Varun Agarwal',
    img: 'https://innogeeks-images.s3.ap-south-1.amazonaws.com/Varun+Agarwal.jpg',
    review: 'Core Lead',
    link: ['https://www.linkedin.com/in/1010varun/']
  }
];

const settings={
  dots:true,
  
  infinite:true,
  speed:500,
  slidesToShow: 3, 
  slidesToScroll:1,
  autoplay: true,
  autoplaySpeed: 2000,
  responsive: [
    {
      breakpoint: 950,
      settings: { 
        dots: false,
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 630,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
}




export default function Carousel() {
//  const [setLink] = useState('');
 useEffect(()=>{
   aos.init(1800)
 },[]) 
 return (
  <div className ="w-3/4 m-auto" id='testimonial'>
    <h1 data-aos="fade-down" className="text-6xl max-md:text-4xl py-4 px-4 text-center ml-[80px] max-md:ml-0"  style={{
        fontFamily: "cabin-sketch-regular",
        fontWeight: "400",
        fontStyle: "normal",
        color: "white",
      }}>
        Meet the Team 
      </h1>
    <div className="mt-20" data-aos="fade-down">
    <Slider{...settings}>
      {data.map((d,index) =>(
        <div key={index} className=" h-[450px] text-black rounded-xl">
          <div className="h-56 rounded-t-xl footer-contact flex justify-center items-center">
            <img key={d.name} src={d.img} alt="" className="h-44 w-44 rounded-full object-cover"/>
          </div>

          <div className="flex flex-col justify-center items-center gap-4 p-4 font-[maiandra] rounded-xl bg-white">
            <p className='text-xl font-semibold'>{d.name}</p>
            <p className='font-bold'>{d.review}</p>
            <button key={d.name} className='footer-button hover:bg-[#17A2C9] hover:scale-110' onClick={()=> openLink(d.link)}>Connect</button>
          </div>
        </div>
      ))}
    </Slider>
    </div>
  </div>
 );
}
